import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LocizeBackend from 'i18next-locize-backend'
import { uniq } from 'lodash'
import { getAssessmentTypes } from 'efqm-theme/assessments/getAssessmentParts'

/**
 * Jsdoc comment for eslint
 */
export async function init() {
  // eslint-disable-next-line no-console
  console.log(process.env)
  await i18n
    .use(LocizeBackend)
    .use(initReactI18next)
    .init({
      fallbackLng: 'en',
      defaultNS: 'en_AB',
      ns: [
        'en_AB',
        ...uniq(
          getAssessmentTypes()
            .map(x => x.translationNameSpace)
            .filter(x => x)
        ),
      ],
      lng: 'en',
      keySeparator: false,
      nsSeparator: false,
      interpolation: {
        escapeValue: false,
      },
      react: { useSuspense: false },
      returnEmptyString: false,
      backend: {
        projectId: 'b0af03f9-45c0-4598-a1f6-371e2c44ba2e',
        apiKey: '17e16370-6924-465a-bc5e-6b4de20f7bb4',
        translatedPercentageThreshold: 0,
        referenceLng: 'en',
        onSaved: (lng, ns) => {
          // eslint-disable-next-line no-console
          console.log('onSaved', lng, ns)
        },
      },
    })

  return i18n
}
