import React, { useEffect, useContext, useState } from 'react'
import { ClientContext } from 'graphql-hooks'
import { ThemeProvider } from '@material-ui/styles'
import {
  createMuiTheme,
  StylesProvider,
  jssPreset,
} from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import './src/styles/global.css'
import { useTranslation } from 'react-i18next'
import { create } from 'jss'
import rtl from 'jss-rtl'

import {
  AuthWrapper,
  AuthContext,
  RootWrapper,
  Layout,
  ThemeWrapper,
  makeGraphQLClient,
  initGraphQLClient,
  theme,
  useUserOrgs,
  useUserCertificates,
  LOCAL_STORAGE_LANGUAGE_KEY,
} from 'gatsby-components'
import awsConfig from './utils/aws-exports'
import * as i18n from './utils/i18n'
import AppFooter from './src/components/AppFooter'
import MainToolbar from './src/components/MainToolbar'
import { Questionnaire2020DataWrapper } from './src/components/Questionnaire2020DataContext'

const jss = create({ plugins: [...jssPreset().plugins, rtl()] })

const client = makeGraphQLClient(process.env.GATSBY_GRAPHQL_API)

export async function onClientEntry() {
  await i18n.init()
}

const AuthInitializationWrapper = ({ element }) => {
  const { i18n } = useTranslation()
  const lang = window.localStorage.getItem(LOCAL_STORAGE_LANGUAGE_KEY)
  if (lang && i18n.language !== lang) {
    i18n.changeLanguage(lang)
  }
  const isRightToLeft = lang === 'ar'

  return (
    <RootWrapper
      client={client}
      ClientContext={ClientContext}
      muiTheme={createMuiTheme({
        ...theme.muiTheme,
        direction: isRightToLeft ? 'rtl' : 'ltr',
        overrides: isRightToLeft
          ? {
              ...theme.muiTheme.overrides,
              MuiCssBaseline: {
                '@global': {
                  'html *': {
                    letterSpacing: 'normal !important',
                  },
                },
              },
            }
          : theme.muiTheme.overrides,
      })}
      ThemeProvider={ThemeProvider}
      ThemeWrapper={ThemeWrapper}
      CssBaseline={CssBaseline}
    >
      <StylesProvider jss={jss}>
        <AuthWrapper>
          <Questionnaire2020DataWrapper>{element}</Questionnaire2020DataWrapper>
        </AuthWrapper>
      </StylesProvider>
    </RootWrapper>
  )
}
export const wrapRootElement = ({ element }) => {
  return <AuthInitializationWrapper element={element} />
}

const PageWrapper = ({ darkToolbar, location, children }) => {
  const { setIsAuthInitialized } = useContext(AuthContext)
  const [graphQLIsInitialized, setGraphQLIsInitialized] = useState(false)

  useUserOrgs()
  useUserCertificates()

  useEffect(() => {
    const init = async () => {
      setGraphQLIsInitialized(false)

      try {
        await initGraphQLClient(client, awsConfig)
      } catch {
        // error
      }

      setIsAuthInitialized(true)
      setGraphQLIsInitialized(true)
    }
    init()
  }, [location, setIsAuthInitialized])

  return (
    <Layout
      darkToolbar={darkToolbar}
      AppFooter={AppFooter}
      MainToolbar={MainToolbar}
      isInitialLoad={!graphQLIsInitialized}
    >
      {graphQLIsInitialized ? children : null}
    </Layout>
  )
}

export const wrapPageElement = ({ element, props }) => (
  <PageWrapper
    darkToolbar={props.location.pathname === '/'}
    location={props.location.pathname}
  >
    {element}
  </PageWrapper>
)
