// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-auth-js": () => import("./../../../src/pages/auth.js" /* webpackChunkName: "component---src-pages-auth-js" */),
  "component---src-templates-assessment-js": () => import("./../../../src/templates/assessment.js" /* webpackChunkName: "component---src-templates-assessment-js" */),
  "component---src-templates-contributors-assessors-js": () => import("./../../../src/templates/contributors-assessors.js" /* webpackChunkName: "component---src-templates-contributors-assessors-js" */),
  "component---src-templates-criterion-js": () => import("./../../../src/templates/criterion.js" /* webpackChunkName: "component---src-templates-criterion-js" */),
  "component---src-templates-criterion-part-js": () => import("./../../../src/templates/criterion-part.js" /* webpackChunkName: "component---src-templates-criterion-part-js" */),
  "component---src-templates-feedback-report-js": () => import("./../../../src/templates/feedback-report.js" /* webpackChunkName: "component---src-templates-feedback-report-js" */),
  "component---src-templates-find-out-more-js": () => import("./../../../src/templates/find-out-more.js" /* webpackChunkName: "component---src-templates-find-out-more-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-management-report-js": () => import("./../../../src/templates/management-report.js" /* webpackChunkName: "component---src-templates-management-report-js" */),
  "component---src-templates-profile-js": () => import("./../../../src/templates/profile.js" /* webpackChunkName: "component---src-templates-profile-js" */),
  "component---src-templates-questionnaire-2020-feedback-report-js": () => import("./../../../src/templates/questionnaire-2020-feedback-report.js" /* webpackChunkName: "component---src-templates-questionnaire-2020-feedback-report-js" */),
  "component---src-templates-questionnaire-2020-js": () => import("./../../../src/templates/questionnaire-2020.js" /* webpackChunkName: "component---src-templates-questionnaire-2020-js" */),
  "component---src-templates-questionnaire-feedback-report-js": () => import("./../../../src/templates/questionnaire-feedback-report.js" /* webpackChunkName: "component---src-templates-questionnaire-feedback-report-js" */),
  "component---src-templates-questionnaire-js": () => import("./../../../src/templates/questionnaire.js" /* webpackChunkName: "component---src-templates-questionnaire-js" */),
  "component---src-templates-questionnaire-scoring-js": () => import("./../../../src/templates/questionnaire-scoring.js" /* webpackChunkName: "component---src-templates-questionnaire-scoring-js" */)
}

