import React, { useState } from 'react'
import { useMutation, useManualQuery } from 'graphql-hooks'

import {
  upsertQuestionnaireNonUserDataMutation,
  getNonUserQuestionnairesFeedbackReportData,
} from '../queries'
import { getAssessmentId } from '../utils/url'

// This context object provides a way to share Questionnaire data between
// the questionnaire page and the feedback report
// the assessment key `key` is used so that the type of questionnaire
// can be differentiated (aka specific lens questionnaire, standard questionnaire ex.)

const QuestionnaireDataContext = React.createContext()

const isQuestionnaireInDB = () => !!getAssessmentId(window.location)

const Questionnaire2020DataWrapper = ({ children }) => {
  // window in unavailable during SSR hence the checks for window
  const [questionnaireData, setState] = useState({})
  const [upsertQuestionnaireNonUserData] = useMutation(
    upsertQuestionnaireNonUserDataMutation
  )
  const [fetchNonUserData] = useManualQuery(
    getNonUserQuestionnairesFeedbackReportData
  )

  const getQuestionnaireData = key => questionnaireData[key] || {}

  const hasStoredQuestionnaireData = key =>
    typeof window !== 'undefined' && !!window.localStorage.getItem(key)

  const reloadQuestionnaire = key => {
    if (
      typeof window !== 'undefined' &&
      hasStoredQuestionnaireData(key) &&
      !isQuestionnaireInDB()
    ) {
      setQuestionnaireData(key, JSON.parse(window.localStorage.getItem(key)))
    }
  }

  const setQuestionnaireData = async (key, data) => {
    setState({ ...questionnaireData, [key]: data })

    if (!isQuestionnaireInDB()) {
      await upsertQuestionnaireNonUserData({ variables: { key, ...data } })

      if (typeof window !== 'undefined') {
        window.localStorage.setItem(key, JSON.stringify(data))
        window.localStorage.setItem('email', data.email)
      }
    }
  }

  const getLastUsedEmailAddress = () =>
    typeof window !== 'undefined' && window.localStorage.getItem('email')
      ? window.localStorage.getItem('email')
      : ''

  const clearQuestionnaireData = key =>
    setState({ ...questionnaireData, [key]: {} })

  const resetQuestionnaire = key => {
    if (window) window.localStorage.removeItem(key)
    clearQuestionnaireData(key)
  }

  const getContextDataForChart = key => {
    // prepares context data to resemble data from HasuraDB
    const {
      questionnaireName,
      values = {},
      tables = {},
    } = getQuestionnaireData(key)

    return {
      name: questionnaireName,
      key,
      questionnaire_tables: Object.keys(tables).map(key => ({
        pillar_key: key,
        criterion_key: key,
        part_number: 1,
        table_key: 'background-info-table',
        table_values: [{ 'background-info': tables[key] }],
      })),
      questionnaire_scores: Object.keys(values).map(key => ({
        pillar_key: key,
        criterion_key: key,
        part_number: 1,
        scoring_values: { [key]: values[key] },
      })),
    }
  }

  const getSavedNonUserQuestionnaireData = async (key, email) => {
    // For admins to load Non User Feedback Report data into context
    const { data, error } = await fetchNonUserData({
      variables: { key, email },
    })
    if (!error) {
      const {
        email,
        scores,
        tables,
        title,
      } = data.questionnaire_non_users_by_pk
      setState({
        // structure the loaded data to match that how data is stored locally for non-users
        [key]: {
          email,
          questionnaireName: title,
          values: scores,
          tables,
        },
      })
    }
  }

  return (
    <QuestionnaireDataContext.Provider
      value={{
        getQuestionnaireData,
        setQuestionnaireData,
        clearQuestionnaireData,
        reloadQuestionnaire,
        resetQuestionnaire,
        getContextDataForChart,
        getLastUsedEmailAddress,
        getSavedNonUserQuestionnaireData,
      }}
    >
      {children}
    </QuestionnaireDataContext.Provider>
  )
}

export default QuestionnaireDataContext

export { Questionnaire2020DataWrapper }
